<template>
  <div class='dashboard-page'>
    <a-row :gutter="[16,16]">
      <a-col :md="8">
        <a-card title="Active Users" :bordered="false">
          <span>
            {{ activeUsers || 0 }}
          </span>
        </a-card>
      </a-col>
      <a-col :md="8">
        <a-card title="Total Bets" :bordered="false">
          <span>
            {{ totalBets ? totalBets.toFixed(1) : 0 }}
          </span>
        </a-card>
      </a-col>
      <a-col :md="8">
        <a-card title="Total Payouts" :bordered="false">
          <span>
            {{ totalPayouts ? totalPayouts.toFixed(1) : 0 }}
          </span>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="[16,16]">
      <a-col :md="12">
        <a-card title="Total System Fee" :bordered="false">
          <span>
            {{ totalSystemFee ? totalSystemFee.toFixed(1) : 0 }}
          </span>
        </a-card>
      </a-col>
      <a-col :md="12">
        <a-card title="Total Jackpot Fee" :bordered="false">
          <span>
            {{ totalJackpotFee ? totalJackpotFee.toFixed(1) : 0 }}
          </span>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import StatisticsService from "@/services/app/statistics.service";

export default {
  name: 'DashboardPage',
  data() {
    return {
      activeUsers: 0,
      totalBets: 0,
      totalPayouts: 0,
      totalSystemFee: 0,
      totalJackpotFee: 0,
      breadcrumb: [
        {
          path: '/dashboard',
          label: 'Summary statistics'
        }
      ],
    }
  },
  mounted() {
    this.setBreadcrumb(this.breadcrumb)
    this.onFetchData()
  },

  methods: {
    async onFetchData() {
      const response = await StatisticsService.getList();
      if(response.code === 1) {
        const {totalUsers, totalBets, totalPayOuts,
          totalSystemFee, totalJackpotFee } = response.data;

        this.activeUsers = totalUsers;
        this.totalBets = totalBets;
        this.totalPayouts = totalPayOuts;
        this.totalSystemFee = totalSystemFee;
        this.totalJackpotFee = totalJackpotFee;
      }
    }
  }

}
</script>

<style scoped>
.dashboard-page {
  padding: 24px;
}
</style>
